<template>
  <div class="container">
    <table class="table text-start">
      <thead>
      <tr>
        <th scope="col" @click="sortby('filename')">文件名</th>
        <th scope="col" @click="sortby('size')">大小</th>
        <th scope="col" @click="sortby('timestamp')">上传时间</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="file in sortedDownloads" :key="file.filename">
        <td><a :href="file.download_url">{{ file.filename }}</a></td>
        <td>{{ Math.round((file.size / 1048576) * 100) / 100 }} MB</td>
        <td>{{ getLocalTime(file.timestamp) }}</td>
      </tr>
      </tbody>
    </table>
    <div class="row justify-content-center">
      <div class="col-4">
        <button type="button" class="btn btn-info float-left" @click="prevPage"><i class="fa fa-arrow-left"
                                                                                   aria-hidden="true"></i> 上一页
        </button>
      </div>
      <div class="col-4">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">每页显示</div>
          </div>
          <select v-model="pageSize" class="custom-select mr-sm-2" id="perPageSelector">
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-info float-right" @click="nextPage">下一页 <i class="fa fa-arrow-right"
                                                                                        aria-hidden="true"></i></button>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {computed, onMounted, ref} from "vue";
import api from "@/utils/api";
import _ from "lodash";

interface DownloadFileInfo {
  filename: string,
  timestamp: number,
  size: number,
  download_url: string,
  expires_in: number
}

export default {
  name: "download-index",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(){
    const downloads = ref<DownloadFileInfo[]>([])
    const currentSort = ref<string>('timestamp')
    const currentSortDir = ref<string>('desc')
    const pageSize = ref<number>(30)
    const currentPage = ref<number>(1)
    const sortedDownloads = computed(() => {
      console.log(Object.values(downloads.value))
      return Object.values(downloads.value).sort((a,b) => {
        let modifier = 1;
        if(currentSortDir.value === 'desc') modifier = -1;
        if(_.get(a, currentSort.value) < _.get(b, currentSort.value)) return -1 * modifier;
        if(_.get(a, currentSort.value) > _.get(b, currentSort.value)) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (currentPage.value-1)*pageSize.value;
        let end = currentPage.value*pageSize.value;
        if(index >= start && index < end) return true;
      });
    })
    const sortby = (s:string) => {
      if(s === currentSort.value) {
        currentSortDir.value = currentSortDir.value==='asc'?'desc':'asc';
      }
      currentSort.value = s;
    }
    const nextPage = () => {
      if((currentPage.value*pageSize.value) < Object.keys(downloads.value).length) currentPage.value++;
    }
    const prevPage = () => {
      if(currentPage.value > 1) currentPage.value--;
    }
    const getLocalTime = (time:number) => {
      let ts = time;
      let t,y,m,d,h,i,s;
      t = ts ? new Date(ts*1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth()+1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可根据需要在这里定义时间格式
      return y+'年'+(m<10?'0'+m:m)+'月'+(d<10?'0'+d:d)+'日 '+(h<10?'0'+h:h)+':'+(i<10?'0'+i:i)+':'+(s<10?'0'+s:s);
    }
    onMounted(()=>{
      api<{
        code: number,
        data: DownloadFileInfo[],
        msg: string
      }>('/download','get')
      .then((downloadsData) => {
        downloads.value = downloadsData.data
        console.log(downloadsData.data)
      })
      .catch(error => {
        console.log(error)
      })
    })
    return {
      downloads,
      currentSort,
      currentSortDir,
      pageSize,
      currentPage,
      sortedDownloads,
      getLocalTime,
      sortby,
      nextPage,
      prevPage
    }
  }
}
</script>

<style scoped>
th {
  cursor: pointer;
}
</style>